import React from 'react';
import Link from '../components/common/Link';
import PageTitle from '../components/common/PageTitle';

const NotFound = () => (
  <section style={{ backgroundColor: '#3a3a3a', color: 'white' }}>
    <PageTitle title="Not Found" />
    <div className="container">
      <div className="col-md-4">
        <img src="/images/404_error.png" alt="404 Not Found" className="img-responsive" />
      </div>
      <div className="col-md-8">
        <h2>Page not available</h2>
        <p>The link you followed may be broken, or the page may have been removed.</p>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </div>
  </section>
);

export default NotFound;
